import { all } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import DjHubAnalyticsSaga from "./dashboard/DjHubAnal/saga";
import Training from "./dashboard/Training/saga";
import Courses from "./dashboard/Courses/saga";
import ConverterKitTags from "./dashboard/ConverterKitTags/saga";
import Lessons from "./dashboard/Lessons/saga";
import premiumArticlesSaga from "./dashboard/PremiumArticles/saga";
import premiumArticlesSectionsSaga from "./dashboard/PremiumArticlesSections/saga";
import premiumArticlesTagsSaga from "./dashboard/PremiumArticlesTags/saga";
import premiumArticlesCategoriesSaga from "./dashboard/PremiumArticlesCategories/saga";
import UsersSaga from "./dashboard/Users/saga";
import CommentsSaga from "./dashboard/Comments/saga";
import CouponsSaga from "./dashboard/Coupons/saga";
import SaleSaga from "./dashboard/Sale/saga";
import AdminsSaga from "./dashboard/Admins/saga";
import PaymentsSaga from "./dashboard/Payments/saga";
import settingsSaga from "./dashboard/Settings/saga";
import notificationsSaga from "./dashboard/Notifications/saga";
import bundleSaga from "./dashboard/Bundles/saga";
import rewardSaga from "./dashboard/Rewards/saga";

export default function* rootSaga() {
  yield all([
    //public
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    Training(),
    Courses(),
    ConverterKitTags(),
    Lessons(),
    premiumArticlesSaga(),
    premiumArticlesSectionsSaga(),
    premiumArticlesTagsSaga(),
    premiumArticlesCategoriesSaga(),
    UsersSaga(),
    CommentsSaga(),
    CouponsSaga(),
    SaleSaga(),
    AdminsSaga(),
    PaymentsSaga(),
    settingsSaga(),
    notificationsSaga(),
    DjHubAnalyticsSaga(),
    bundleSaga(),
    rewardSaga(),
  ]);
}
