export const USER_LIST_LIMIT = 15;
export const COUPONS_LIST_LIMIT = 15;
export const USAGES_LIST_LIMIT = 15;
export const ADMINS_LIST_LIMIT = 15;
export const PAYMENTS_LIST_LIMIT = 15;
export const NOTIFICATIONS_LIST_LIMIT = 15;

export const ORDER_DESC = "desc";
export const ORDER_ASC = "asc";
export const SORT_ACTION_TYPE = "sort";

export const FILTER_BY_INACTIVE = "INACTIVE";
export const FILTER_BY_ACTIVE = "ACTIVE";
export const FILTER_BY_BLOCKED = "BLOCKED";

export const SEARCH_DELAY = 300;
