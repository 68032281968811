import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import {
  ORDER_ASC,
  ORDER_DESC,
  SORT_ACTION_TYPE,
} from "../constants/paginationConstants";
import qs from "querystring";

import deleteObjectProperty from "../helpers/deleteObjectProperty";
import queryParser from "../helpers/queryParser";
import getArrayFromQuery from "../helpers/getArrayQuery";

const useTable = (
  entityState,
  getListAction,
  tableLimit,
  clearCreatedAction,
  needUpdateListClearAction
) => {
  const location = useLocation();
  const history = useHistory();
  const { data, pagination, loading, created, needUpdateList } = entityState;
  const query = useMemo(() => queryParser(location.search), [location]);

  const prevSerchQuery = useRef("");

  const handleTableChange = (type, { page, searchText, sortField }) => {
    let params = {
      ...query,
      offset: tableLimit * (page - 1),
      q: searchText,
    };
    if (type === SORT_ACTION_TYPE) {
      params.orderType = query.orderType === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
      params.orderBy = sortField;
    }

    if (!searchText) {
      params = deleteObjectProperty("q", params);
    }

    if (
      (searchText && searchText !== prevSerchQuery.current) ||
      (!searchText && prevSerchQuery.current)
    ) {
      prevSerchQuery.current = searchText;
      history.push({
        search: qs.stringify({ ...params, offset: 0 }),
      });
    } else {
      history.push({
        search: qs.stringify(params),
      });
    }
  };

  const getList = () => {
    const newQuery = {
      ...query,
      limit: tableLimit,
    };
    if (query.statuses) {
      newQuery.statuses = getArrayFromQuery(query.statuses);
    }
    getListAction(newQuery);
  };

  useEffect(() => {
    getList();
  }, [query]);

  useEffect(() => {
    if (!!created && !!clearCreatedAction) {
      getList();
      clearCreatedAction();
    }
  }, [created, clearCreatedAction]);

  useEffect(() => {
    if (needUpdateList && !!needUpdateListClearAction) {
      getList();
      needUpdateListClearAction();
    }
  }, [needUpdateList, needUpdateListClearAction]);

  return { data, pagination, loading, handleTableChange, query };
};

export default useTable;
